import axios from '../utils/axios';

export const getChannelList = async (page, perPage) => {
  const response = await axios.get(`/api/channel/list?page=${page}&perPage=${perPage}`);
  return response;
};

export const addChannel = async (postData) => {
  const response = await axios.post('/api/channel/create', postData);
  return response;
};

export const getChannel = async (id) => {
  const response = await axios.get(`/api/channel/view/${id}`);
  return response;
};

export const updateChannel = async (id, postData) => {
  const response = await axios.patch(`/api/channel/update/${id}`, postData);
  return response;
};

export const searchChannel = async (search) => {
  const response = await axios.get(`/api/channel/search?${search}`);
  return response;
};

export const initWhatsApp = async (id) => {
  const response = await axios.get(`/api/channel/init/${id}`);
  return response;
};

export const resetWhatsApp = async (id) => {
  const response = await axios.get(`/api/channel/reset/${id}`);
  return response;
};

// WA GO
export const initWaGoSession = async (id) => {
  const response = await axios.get(`/api/channel/init/${id}`);
  return response;
};

export const reconnectGoSession = async (id) => {
  const response = await axios.get(`/api/channel/reconnect/${id}`);
  return response;
};

export const logoutGoSession = async (id) => {
  const response = await axios.get(`/api/channel/logout/${id}`);
  return response;
};

export const getUserProfilePic = async (id, data) => {
  const response = await axios.post(`/api/channel/avatar/${id}`, data);
  return response;
};

export const getChannelGroups = async (id) => {
  const response = await axios.get(`/api/channel/groups/${id}`);
  return response;
};
