import { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { getChannelList } from '../../../service/ChannelSer';
import useAuth from '../../../hooks/useAuth';
import { isWhatsEra } from '../../../config';

// ----------------------------------------------------------------------
export default function ExpireLabel() {
  const { user } = useAuth();

  const [channels, setChannels] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchChannels = async () => {
    setIsLoading(true);
    try {
      const { data } = await getChannelList(1, 999);
      const now = new Date();
      const expiringChannel = data?.channels?.find((channel) => {
        const expireDate = new Date(channel?.expireAt);
        const remainingDays = Math.ceil((expireDate - now) / (1000 * 60 * 60 * 24));
        return remainingDays <= 10;
      });

      if (expiringChannel) {
        const expireDate = new Date(expiringChannel.expireAt);
        const remainingDays = Math.ceil((expireDate - now) / (1000 * 60 * 60 * 24));

        setChannels({ ...expiringChannel, remainingDays });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdatePlan = () => {
    const message = encodeURIComponent(
      `Hi *WhatsEra*,\n\nI would like to upgrade my plan. Here are my details:\n\n  *Name*: ${user?.name}\n  *Email*: ${user?.email}\n  *Mobile*: ${user?.mobile}`
    );
    const whatsappNumber = '919024620837';
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      {!user?.freeTrial?.isTrial && !isLoading && Object.keys(channels).length !== 0 && isWhatsEra && (
        <Stack direction="row" spacing={1} sx={{ background: '#FFF7CD', py: 1, px: 2, borderRadius: 1 }}>
          <Typography variant="subtitle2" color={channels?.remainingDays <= 0 ? '#bd3447' : 'black'}>
            {channels?.remainingDays <= 0
              ? 'Your plan has expired. Please renew to continue enjoying uninterrupted access.'
              : `Your plan is about to expire in ${channels?.remainingDays} days. Please renew your subscription to avoid interruptions.`}
          </Typography>
          <Button variant="contained" size="small" sx={{ background: '#f56262' }} onClick={handleUpdatePlan}>
            Renew
          </Button>
        </Stack>
      )}
    </>
  );
}
